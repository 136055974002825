div#v-addfunds {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div#v-addfunds-inner {
    padding:8px;

    div.amount-and-submit {
      padding: 4px 0 4px 0;
      display: flex;
      flex-direction: column;
      div.fields {
        padding-bottom: 16px;
        div.name {
          display: flex;
          flex-direction: row;
        }
        div.firstName,
        div.lastName {
          width: 150px;
        }
        div.email {
          width: 300px;
        }
        div.amount {
          input[type=text] {
            width:80px;
            text-align: right;
          }
        }
      }
      div.buttons {
        margin-bottom: 8px;
      }
      div.validation {
        margin-bottom: 8px;
        div.validation-item {
          padding:2px;
          color: red;
        }
      }
    }

    @media screen and (min-width: 700px) {
      padding-left:100px;
    }
  }

  

  p {
    margin-bottom: 8px;
  }

  
}

iframe.iframe_payrexx {
  border:50px;
  width: 100%;
  transition: height 0.25s ease-in;
  //height: 578px;
}