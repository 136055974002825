@import "../../variables.scss";

div.c-input,
div.c-input {
  margin:2px;
  div.c-input-label {
    color: $color_accent;
  }
  padding:0;
  input[type=text] {
    font-size:17px;
    padding: 6px 4px 6px 4px;
    border:1px solid $color_accent;
    display: inline-block;
    border-radius: 2px;
    width:100%;
  };
  input[type=text]:disabled {
    color: silver;
    border-color: silver;
  }
}
