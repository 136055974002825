@import "../../variables.scss";

div#v-catalogue {
  padding: 8px;
  margin-bottom: 16px; // NOTE: there is also div.cart-foil ... its size is changed dynamically via javascript

  div.activities {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  div.sort,
  div.tags {
    div.heading {
      font-size: $font-size-small;
    }
  }

  div.tags {
    padding:4px 0 4px 0;
    
    div.tags-inner {
      .tag {
        display: inline-block;
        cursor: pointer;
        margin:0 2px 0 2px;
        .tag-inner {
          font-size: 14px;
          font-weight: 200;
          display: inline-block;
          padding:4px 8px 4px 8px;
          background-color: $color-accent-inverted;
          color: $color-accent;
          border-radius: 2px;
          border:1px solid $color-accent;
        }
      }
      .tag:first-child {
        margin-left: 0;
      }
      .tag:last-child {
        margin-right: 0;
      }
      .tag.active {
        .tag-inner {
          background-color: $color-accent;
          color: $color-accent-inverted;
        }
      }
    }
  }

  div.count {
    font-size: $font-size-small;
  }
}