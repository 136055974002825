@import "../../variables.scss";

div#v-done {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div#v-done-inner {
    padding: 8px;
    text-align: center;
    div.heading {
      text-align: left;
    }
    div.happy {
      padding:16px;
      img {
        height:100px;
        width:auto;
      }
    }
  }
}