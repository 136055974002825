@import "../../variables.scss";

div#v-adventure-info {
  padding: 8px;

  div.max {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    div.info {
      padding:0 8px 0 8px;
    }
    table {
      width: 100%;
      th {
        font-weight: 100;
        font-size:1.5em;
      }
      td {
        vertical-align: top;
      }
      td:first-child {
        padding: 1px 4px 0 0;
      }
      td:last-child {
        width: 100%;;
      }
    }
    div.help {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  div.min {
    display:flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    top:0; left:0;
    background-color: white;
    box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.3);

    div.min-inner {
      position: relative;
      padding:8px;
      width:100%;
      max-width: $max-width;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      
      div.info {
        padding: 0 4px 0 4px;
        div.heading {
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          div.name {
            padding:0 4px 0 4px;
            max-width: 100%;
            white-space: nowrap;
            overflow-x: hidden;
          }
        }
        div.details {
          display: flex;
          flex-direction: row;
          div {
            padding:0 4px 0 4px;
          }
        }
      }

      div.help {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
  div.min.hidden {
    display:none;
  }
}