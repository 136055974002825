@import "../../variables.scss";

div#v-finalize {
  //min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div#v-finalize-inner {
    padding: 8px;
    div#v-finalize-items,
    div#v-finalize-charities,
    div#v-finalize-submit {
      padding: 8px 0 32px 0;
      div.console {
        padding:8px 0 0 0;
      }
    }
  
    div#v-finalize-items {
      table {
        tr.total {
          td {
            border-top: 1px solid silver;
            padding-top: 2px;
          }
        }
        td {
          vertical-align: top;
          div.image {
            display: inline-block;
            width:32px;
            height:32px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height:auto;
            }
          }
        }
      }
  
    }
  
    div#v-finalize-charities {
      div.charities {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        div.c-charity {
          display:inline-block;
          cursor: pointer;
          margin:2px;
          border:1px solid $color-accent;
          background-color: $color-accent-inverted;
      
          div.c-charity-inner {
            padding:4px; 
          }
          h3 {
            color: $color-accent;
          }
          div.logo {
            text-align: center;
            img {
              height:50px;
              width: auto;
            }
          }
        }
        div.c-charity.selected {
          color: $color-accent-inverted;
          background-color: $color-accent;
  
          h3 {
            color: $color-accent-inverted;
          }
        }
      }
    }
  }
  
 
}