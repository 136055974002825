// z-index
$z-index-regular:100;
$z-index-modal:1000;

// dimensions
$max-width: 1200px;

// font-sizes
$font-size-small: 0.8em;

// colors
$color-text: #111111;

$color-accent: #6aa84f;
$color-accent-inverted:white;
$color-accent-light: #bbef91;
$color-accent-lighter: #d2e5cd;
$color-accent-lightest: #eef8ef;

$color-error-inverted: #eeffff;

$color-disabled: #ffffff;
$background-color-disabled: #555555;

$color-ok: #6aa84f;
$color-error: #ee0000;
$color-warning: #eea300;