@import "./variables.scss";

// universal reset (might be a bit much, e.g. for inputs)
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline:none;
}

/* tags */
html, body {
  height: 100%;
}
body {
  font-family: "brandon-grotesque",sans-serif;
  font-weight: 100;
  min-height: 100%;
}

h1, h2, h3 {
  font-weight: 200;
}
h1 {
  font-size:2em;
  line-height: 1em;
  padding-bottom: 8px;
  color:$color_accent;
}
h2 {
  font-size: 1.5em;
  line-height: 1em;
  padding-bottom: 8px;
}

p {
  font-weight: 100;
  font-size: 1em;
}

a {
  color: $color_accent;
  text-decoration: underline;
}

table {
  border:0;
  border-collapse: collapse;
  tr {
    th, td {
      text-align: left;
    }
    td {
      vertical-align: text-top;
      padding:0 4px 0 4px;
    }
    td:first-child {
      padding-left:0;
    }
    td:last-child {
      padding-right:0;
    }
    td.right {
      text-align: right;;
    }
  }
}

ul {
  list-style-type: none;
}

/* app root */
div#root {
  height: 100%;
  min-height: 100%;
}

/* common classes */
.clickable {
  cursor: pointer;
  user-select: none;
}
.nowrap {
  white-space: nowrap;
}