div.c-activity {
  display:inline-block;
  
  width: 100%;
  @media screen and (min-width: 700px) {
    width: 50%;
  }
  
  div.c-activity-inner {
    padding:8px;
    border:1px solid silver;
    display:flex;
    flex-direction: row;
    width: 100%;
    div.image {
      width: 158px;
      min-width:158px;
      height: 158px;
      min-height: 158px;
      img {
        width:100%;
        height: auto;  
      }
    }
    div.info {
      padding-left:8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      div.top {
        div.title {
          color:green;
          padding-bottom: 4px;
        }
        div.shortDescription {
          padding-bottom: 4px;
        }
      }
      div.bottom {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
}

div.c-activity:nth-child(even) {
  padding:4px;
  @media screen and (min-width: 700px) {
    padding-right:0;
  }
}
div.c-activity:nth-child(odd) {
  padding:4px;
  @media screen and (min-width: 700px) {
    padding-left:0;
  }
}