@import "../../variables.scss";

div.c-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  background-color: $color_accent;
  width:24px;
  height: 24px;
  border-radius: 50%;
  
  div.c-circle-inner {
    font-size:20px;
    font-weight: 300;
    display:inline-block;
    color:$color_accent_inverted;
  }
}
