div.c-asyncimage {
  opacity: 1;
  transition: opacity 1s;
}
div.c-asyncimage.loading {
  opacity: 0;
}
div.c-asyncimage.loaded {
  //transition: opacity 1s;
  opacity: 1;
}