@import "../../variables.scss";

div#v-error {
  div#v-error-inner {
    padding: 8px;
    display:flex;
    flex-direction: column;
    align-items: center;
    div.heading {
      width: 100%;
      text-align: left;
    }
    div.sad {
      img {
        height:100px;
        width:auto;
      }
    }
    
    div.details-toggle {
      font-size: 0.8em;
      text-decoration: underline;
      color: $color_accent;
    }
    div.details {
      padding: 8px;
      border: 1px solid red;
      margin:4px 0 4px 0;
      font-family: "Courier New", Courier, monospace;
      font-size: 0.8em;
      color:red;
    }
    div.contact,
    div.console {
      padding:16px 0 16px 0;
    }
  }
  
}