@import "../variables.scss";


div#v-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  min-height: 100%;

  color:$color-text;  
  background-color: $color_accent_lightest;
  
  div#v-main-inner {
    height: 100%;
    min-height: 100%;
    padding:0;
    display:flex;
    flex-direction: column;
    width:100%;
    max-width: $max-width;
    background-color:white;
  }
}

div#v-main-modal {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.9);
  //background-color: $color_accent_lightest;
  position: fixed; 
  z-index: $z-index-modal;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow-y: scroll; // auto?
  text-align: center;

  div#v-main-modal-inner {
    position: relative;
    padding:8px;
    display: inline-block;
    background-color: rgb(255, 255, 255); 
    background-color: rgba(255, 255, 255, 0.9);
    width:100%;
    max-width: $max-width;
    //height:100%;

    .close {
      position: absolute;
      top:8px;
      right:8px;
    }
  }
}

