@import "../../variables.scss";

div.c-select {
  padding: 2px;
  border:1px solid $color_accent;
  display: inline-block;
  border-radius: 2px;
  select {
    padding:0 2px 0 2px;
    border:0;
    color: $color_accent;
  }
}