@import "../../variables.scss";

div#v-customize {
  text-align: left;
  display: inline-block;
  padding:0 0 64px 0;
  max-width: 800px;

  div.shortDescription {
    padding-bottom: 8px;
    p {
      font-weight: 200;
    }
  }
  div.longDescription {
    padding-bottom: 8px;
  }

  div.options {
    div.option {
      padding: 2px 0 2px 0;
    }
  }

  table.prices {
    width: 100%;
    td:last-child {
      text-align: right;
    }
    tr.total {
      td {
        font-weight: 200;
      }
    }
  } 

  div.console {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center; 
  }

  div.gallery {
    @media screen and (max-width: 700px) {
      position: relative;
      display:block;
      width: 100%;
      div.current {
        width:100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      div.others {
        position: absolute;
        bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        img {
          border: 1px solid white;
          width:62px;
          height:auto;
        }
      }
    }
    @media screen and (min-width: 700px) {
      display: flex;
      div.current {
        width:80%;
        img {
          width: 100%;
          height: auto;
        }
      }
      div.others {
        width:20%;
        padding-left:8px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    
  }
}