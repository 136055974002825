@import "../../variables.scss";

div#v-nil {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
  div#v-nil-inner {
    text-align: center;
    padding: 8px;
    div.text {
      padding:16px 0 16px 0;
    }
  }
}