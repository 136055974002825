@import "../../variables.scss";

div.c-button {
  display: inline-block;
  border:1px solid;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  span {
    font-weight:100;
  }
  user-select: none;
  
  div.c-button-inner {
    padding:2px;
  } 
}

// kinds
.c-button.primary {
  background-color: $color-accent;
  color:$color-accent-inverted;
  border-color: $color-accent;
}
.c-button.secondary {
  background-color: $color-accent-inverted;
  color:$color-accent;
  border-color: $color-accent;
}
.c-button.delete {
  background-color: $color-error;
  color:$color-error-inverted;
  border-color: $color-error;
}
.c-button.disabled {
  background-color: $background-color-disabled;
  color:$color-disabled;
  border-color: $color-disabled;
}
.c-button.outline-inverted {
  background-color: $color-accent;
  color:$color-accent-inverted;
  border-color: $color-accent-inverted;
}

// sizes
.c-button.normal {
  font-size: 1.1em;
  border-radius: 6px;
  margin: 1px 2px 1px 2px;
  .c-button-inner {
    padding: 4px 8px 4px 8px;
  }
}
.c-button.small {
  font-size: 1em;
  border-radius: 4px;
  margin: 1px 2px 1px 2px;
  .c-button-inner {
    padding:2px 8px 0px 8px;
  }
}
.c-button.xsmall {
  border: 1px solid;
  margin: 1px 2px 1px 2px;
  border-radius: 4px;
  .c-button-inner {
    font-size: 0.9em;
    line-height: 0.9em;
    padding:2px 8px 2px 8px;
  }
}
.c-button.xsmall.primary {
  background: none;
  color: $color-accent;
  border-color: $color-accent;
}
.c-button.xsmall.delete {
  background: none;
  color: $color-error;
  border-color: $color-error;
}

.c-button.wide {
  min-width: 200px;
  max-width: 80%;
  font-size: 1.1em;
  padding:8px;
  margin: 0 2px 0 2px;
  border-radius: 2px;
}
