div.c-logo {
  display: inline-block;
  img {
    height:60px;
    width: auto;
  }
}
div.c-logo.large {
  img {
    height: 100px;
  }
}
div.c-logo.medium {
  img {
    height: 80px;
  }
}
div.c-logo.small {
  img {
    height: 40px;
  }
}