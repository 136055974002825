@import "../../variables.scss";

div#v-start {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;

  div#v-start-inner {
    padding: 8px;
    
    header {
      text-align: center;
    }

    section {
      padding:8px 0 8px 0;
      p {
        padding:0 0 2px 0;
      }
      .speech-bubble {
        padding:8px;
      }
      /* https://leaverou.github.io/bubbly/ */
      .speech-bubble {
        position: relative;
        background: $color-accent-lighter;
        border-radius: .4em;
      }
      .speech-bubble:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-right-color:$color-accent-lighter;
        border-left: 0;
        border-bottom: 0;
        margin-top: -7.5px;
        margin-left: -15px;
      }
    }

    div.comment {
      display:flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      div.avatar {
        padding-right:20px;
        img {
          width: 22px;
          height: auto;
        }
      }
    }

    div.console {
      padding: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width:100%;
    }
  }

}