@import "../../variables.scss";

$toggle-size: 32px;
$toggle-offset: 4px;

div#v-cart {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom:0;
  left:0;
  box-shadow: 0px -5px 5px rgba($color: #000000, $alpha: 0.2);
  
  div#v-cart-inner {
    position: relative;
    width: 100%;
    max-width: $max-width;
    padding:$toggle-size / 2 + $toggle-offset 8px 8px 16px;

    div#v-cart-summary,
    div#v-cart-continue {
      display: inline-block;
    }

    div#v-cart-summary {
      table {
        tr.error {
          color:$color-error;
        }
        tr.ok {
          color:$color-ok;
        }
        td.subtotal {
          border-top: 1px solid silver;
        }
        td.image {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          width:24px;
          height:24px;
          border-radius: 50%;
          img {
            width:64px;
            height: auto;
          }
        }
      }
    }

    div#v-cart-continue {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      div#v-cart-continue-overbudget,
      div#v-cart-continue-charity {
        font-size: 0.9em;         
        span.addfunds {
          color:$color_accent;
          text-decoration: underline;
          cursor:pointer;
        }
      }
    }

    div#v-cart-toggle {
      position: absolute;
      top: $toggle-offset;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      div#v-cart-toggle-inner {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: $toggle-size;
        height: $toggle-size / 2;
        background-color: $color-accent;
        border-radius: 2px;
        
        img {
          width: 50%;
          height: auto;
          transition: transform 0.5s;
        }
      }
    }
    div#v-cart-toggle.rotated {
      div#v-cart-toggle-inner {
        img {
          transform: rotate(180deg);
          transition: transform 0.5s;
        }
      }
    }
  }
  
  
  
}