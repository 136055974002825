@import "../../variables.scss";

div#v-footer,
div#v-footer-minimized {
  padding-top: 32px;
  padding:32px 0 32px 0;
  a {
    text-decoration: none;
    color:$color_accent_inverted;
  }
  a:hover {
    text-decoration: underline;
  }
  div.console {
    text-align: center;
    padding:8px;
  }
}

div#v-footer-minimized {
  text-align: center;
  padding: 8px;

  background-color: $color_accent;
  color: $color_accent_inverted;
}

div#v-footer {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: $color_accent;
  color: $color_accent_inverted;

  div#v-footer-inner {
    max-width: 600px;
    padding-top:16px;
    div.row {
      display: flex;
      flex-direction: column;
    }

    div.primary-links,
    div.secondary-links,
    div.social {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 16px;
    }
    
    div.primary-links {
      font-weight: 200;
    }

    div.social {
      div.follow,
      div.connect {
        display: flex;
        flex-direction: row;
        span {
          padding-right:8px;
        }
        img {
          width:22px;
          height:auto;
        }
      }
    }

    div.contact {
      padding-bottom: 32px;
      div.email,
      div.phone,
      div.address {
        padding-bottom: 4px;;
      }
    }

    div.newsletter {
      padding-bottom: 32px;
      div.form {
        display: flex;
        input {
          width:100%;
          appearance: none;
          border:0;
          margin-right:4px;
          padding:0 4px 0 4px;
        }
        button {
          appearance: none;
          border:0;
          background-color: $color_accent_lightest;
          color: $color_accent;
        }
        button:disabled {
          background-color: silver;
        }
      }
    }
  }
  
}