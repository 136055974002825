div#v-payment-result {
  display:flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  background-color: rgba(255,255,255,0);
  div#v-payment-result-inner {
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 1px 2px silver;
    display: inline-block;
    max-width: 500px;
    div.smiley {
      padding:16px;
      img {
        height:100px;
        width:auto;
      }
    }
    div.text {
      padding: 16px;
      p {
        margin-bottom: 8px;
      }
    }
  }
}